<template>
  <base-section id="keep-in-touch" space="56">
    <v-container>
      <v-row justify="space-between">
        <v-col cols="12" md="5">
          <base-business-info
            title="KEEP IN TOUCH WITH US"
            customText="blackText"
          >
            Send us your queries and express your concerns. We are also open to
            constructive feedback.
          </base-business-info>
        </v-col>

        <v-col cols="12" md="6">
          <base-contact-form title="MAIL US YOUR MESSAGE" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionKeepInTouch",
};
</script>
